<template>
  <div class="card-booking bg-closed-inversed" :style="setStyleSlot(slotObject.step, slotObject.date)">
  </div>
</template>
<script>
export default {
  props: {
    slotObject: {
      type: Object,
      default: () => {},
    },
    playground: {
      type: Object,
      default: () => {},
    },
    stepSlot: {
      type: Number,
      default: 0,
    },
    status: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: () => 1,
    },
    orientation: {
      type: String,
      default: () => 'vertical',
    }
  },
  methods: {
    getDateFormat (dateSlot) {
      return this.$moment.utc(dateSlot).format('HH:mm');
    },
    setStyleSlot(height, date) {
      const timezone = this.$store.getters["auth/currentClub"].timezone;
      const hhFormat = this.$moment.utc(date).tz(timezone).format('HH');
      const mmFormat = this.$moment.utc(date).tz(timezone).format('mm');
      const storedDate = this.$store.getters["planning/getDate"];
      const slotDate = this.$moment.utc(storedDate).tz(timezone);
      slotDate.set({hour:hhFormat,minute:mmFormat,second:0,millisecond:0});
      slotDate.toISOString();
      slotDate.format();

      const styles = {
        'height': height +'px',
        'min-width': height +'px',
        'max-width': height +'px',
        'order': '' + slotDate.unix(),
      };

      if (this.orientation === 'vertical') {
        styles.height = (height * this.size) +'px';
        styles.width = '99.5%';
        styles['min-width'] = '99.5%!important';
        styles['max-width'] = '99.5%!important';
      } else {
        styles.height = 'auto';
        styles['max-height'] = '97%';
        styles['min-width'] = (height * 2 * this.size) +'px';
        styles['max-width'] = (height * 2 * this.size) +'px';
      }

      return styles;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/bookings/_booking-card-closed.scss";
</style>
