import {hydrate} from "@/utils/form";
import {refine} from "@/utils/classes";

export const UNIQUE_BOOKING_TYPE = 'unique';
export const MULTIPLE_BOOKING_TYPE = 'multiple';

export default class Playground {
  id = null;
  club = JSON.parse(localStorage.getItem('selectedClub'));
  name = null;
  shortName = null;
  activities = [];
  bookingType = UNIQUE_BOOKING_TYPE;
  surface = null;
  indoor = true;
  description = null;
  confirmationEmailComment = null;
  linkedPlaygrounds = [];
  enabled = true;
  bookingValidationNeeded = false;
  dayOnlineVisibilityLimit = null;
  minDelayToAllowBooking = 0;
  photos = [];
  bookingCancellationConditionCustomHours = 1;
  bookingCancellationConditionType = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.toEntity) {
        hydrate(this, object);
        this.formatModel();
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);

    refine(this);
  }

  deserialize(object) {
    hydrate(this, object);
    refine(this);
  }

  formatModel() {
    if (this.bookingCancellationConditionCustomHours) {
      this.bookingCancellationConditionCustomHours = this.bookingCancellationConditionCustomHours * 3600;
    }

    if (this.bookingCancellationConditionType !== 'custom') {
      this.bookingCancellationConditionCustomHours = null;
    }

    if (this.dayOnlineVisibilityLimit === '') {
      this.dayOnlineVisibilityLimit = null;
    }

    if (this.minDelayToAllowBooking === null || this.minDelayToAllowBooking === '') {
      this.minDelayToAllowBooking = 0;
    } else {
      this.minDelayToAllowBooking = this.minDelayToAllowBooking * 3600;
    }
  }
}
