<template>
  <div
    v-if="params.orientation === 'vertical'"
    :id="identifier"
    :class="(params.orientation === 'vertical') ? 'container-playground flex-column' : 'container-playground flex-row'"
    :style="(params.orientation === 'vertical') ? 'width: 100%;' : 'max-width: 100%'"
    data-v-sticky-container
  >
    <div
      class="sticky-div"
      v-vue-sticky-directive="{ topSpacing: 60, bottomSpacing: 20 }"
      @affixed-top="headerToStickyPosition"
      @affixed-static="headerToStaticPosition"
    >
      <div>
        <div
          :style="scrollHeader"
          :title="playground.name"
          :class="(params.orientation === 'vertical') ? 'name-block name-block-c' : 'name-block name-block-r'"
        >
          <template
            v-if="display === false"
          >
       <span class="margin-auto">
        <label
          :id="'playground-name ' + playground.id"
          :class="params.orientation === 'vertical' ? 'pointer mb-0' : 'mb-0'"
          @click="showTextField"
        >
          {{ hasShortName ? shortName : playground.name }}
        </label>
        </span>
            <span class="pull-right align-self-start">
              <i id="drag-element" class="pointer drag-class icofont icofont-xs icofont-drag2"></i>
            </span>
          </template>
          <template v-else>
            <div
              v-if="params.orientation === 'vertical'"
              class="d-flex flex-row align-content-center align-items-center w-100 ml-2 mr-2"
            >
              <d-button
                class="d-btn-sm-icon pointer left-borders font-text-title"
                icon="fa fa-close mt-1"
                :class="'d-btn-danger'"
                @on:button-click="cancelUpdate()"
              />
              <b-form-input
                v-model="shortName"
                :placeholder="$t('views.planning.shortName-placeholder')"
                class="background-light-blue-inputs custom-input custom-left-borders"
                size="sm"
                @keyup.enter="updateShortName()"
              />
              <d-button
                class="d-btn-sm-icon pointer custom-left-borders custom-right-borders font-text-title"
                icon="fa fa-check mt-1"
                :class="shortName ? 'd-btn-primary' : 'd-btn-success'"
                @on:button-click="updateShortName()"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <template v-for="(slot, i) of slots">
      <slots
        v-if="slot.bookable && loadedMultipleSlots"
        :key="'playground-' + playground.id + '-slot-key'+ i"
        :size="params.size"
        :slot-object="slot"
        :step-slot="stepSlot"
        :playground="playground"
        :leisure-slots="leisureSlots"
        :orientation="params.orientation"
        @refresh="loader"
        @on:booking-copy="onBookingCopy"
        @on:booking-move="onBookingMove"
        @on:load="onBookingLoaded"
      />
    </template>
    <template v-for="(slot, i) of slotsClosed">
      <closed
        :key="'playground-' + playground.id + '-slot-closed-key'+ i"
        :slot-object="slot"
        :playground="playground"
        :step-slot="stepSlot"
        :orientation="params.orientation"
        :size="params.size"
      />
    </template>
    <template v-for="(booking, i) of bookings">
      <bookings
        v-if="isLoaded && playground.bookingType !== 'multiple'"
        :params="params"
        :booking="booking"
        :size="params.size"
        :playground="playground"
        :playgroundPosition="identifier"
        :orientation="params.orientation"
        :key="'playground-' + playground.id + '-booking-key'+ i"
        @on:booking-reload="loader"
        @on:booking-cancel="onBookingCanceled"
      />
    </template>
  </div>
  <div
    v-else
    :id="identifier"
    :class="(params.orientation === 'vertical') ? 'container-playground flex-column' : 'container-playground flex-row'"
    :style="(params.orientation === 'vertical') ? 'width: 100%;' : 'max-width: 100%'"
  >
    <div>
      <div
        :title="playground.name"
        :class="(params.orientation === 'vertical') ? 'name-block name-block-c' : 'name-block name-block-r'"
      >
        <label
          v-if="display === false"
          :id="'playground-name ' + playground.id"
          :class="params.orientation === 'vertical' ? 'pointer mb-0' : 'mb-0'"
          @click="showTextField"
        >
          {{ hasShortName ? shortName : playground.name }}
        </label>
        <template v-else>
          <div v-if="params.orientation === 'vertical'"
               class="d-flex flex-row align-content-center align-items-center w-100 ml-2 mr-2">
            <d-button
              class="d-btn-sm-icon pointer left-borders font-text-title"
              icon="fa fa-close mt-1"
              :class="'d-btn-danger'"
              @on:button-click="cancelUpdate()"
            />
            <b-form-input
              v-model="shortName"
              :placeholder="$t('views.planning.shortName-placeholder')"
              class="background-light-blue-inputs custom-input custom-left-borders"
              size="sm"
              @keyup.enter="updateShortName()"
            />
            <d-button
              class="d-btn-sm-icon pointer custom-left-borders custom-right-borders font-text-title"
              icon="fa fa-check mt-1"
              :class="shortName ? 'd-btn-primary' : 'd-btn-success'"
              @on:button-click="updateShortName()"
            />
          </div>
        </template>
      </div>
    </div>
    <!-- locally installed, namespaced sticky -->
    <template v-for="(slot, i) of slots">
      <slots
        v-if="slot.bookable && loadedMultipleSlots"
        :key="'playground-' + playground.id + '-slot-sticky-key'+ i"
        :slot-object="slot"
        :playground="playground"
        :step-slot="stepSlot"
        :leisure-slots="leisureSlots"
        :orientation="params.orientation"
        :size="params.size"
        @refresh="loader"
        @on:booking-copy="onBookingCopy"
        @on:booking-move="onBookingMove"
      />
    </template>
    <template v-for="(slot, i) of slotsClosed">
      <closed
        :key="'playground-' + playground.id + '-slot-sticky-closed-key'+ i"
        :slot-object="slot"
        :playground="playground"
        :step-slot="stepSlot"
        :orientation="params.orientation"
        :size="params.size"
      />
    </template>
    <template v-for="(booking, i) of bookings">
      <bookings
        v-if="isLoaded  && playground.bookingType !== 'multiple'"
        :playgroundPosition="identifier"
        :key="'playground-' + playground.id + '-booking-sticky-key'+ i"
        :booking="booking"
        :params="params"
        :playground="playground"
        :orientation="params.orientation"
        :size="params.size"
        @on:booking-reload="loader"
        @on:booking-cancel="onBookingCanceled"
        @on:load="onBookingLoaded"
      />
    </template>
  </div>
</template>
<script>

import Closed from "@views/planning/schedule/playgrounds/bookings/cards/Closed";
import {getPlaygroundMultipleSlots, putClubPlayground} from "@api/doinsport/services/playground/playground.api";
import VueStickyDirective from "@renatodeleao/vue-sticky-directive";
import {hydrate} from "@/utils/form";
import {MULTIPLE_BOOKING_TYPE} from "@/classes/doinsport/Playground";
import {LEISURE_TYPE, LESSON_TYPE} from "@/classes/doinsport/Price";
import {YEAR_DASH_MONTH_DASH_DAY} from "@/utils/date";

export default {
  directives: {
    VueStickyDirective
  },
  props: {
    identifier: {
      type: Number,
      default: () => 0,
    },
    count: {
      type: Number,
      default: () => 1,
    },
    params: {
      type: Object,
      default: () => {
      },
    },
    playground: {
      type: Object,
      default: () => {
      },
    },
    playgroundsBookings: {
      type: Array,
      default: () => [],
    },
    date: {
      type: Object,
      default: () => {
      },
    },
    scrollPosition: {
      type: Number,
      default: 0
    },
    verticalScroll: {
      type: Number,
      default: 0
    },
    schedule: {
      type: Object,
      default: () => {
        return {
          today: true,
          tomorrow: false
        }
      },
    },
  },
  data: () => ({
    stepSlot: 15,
    isBusy: false,
    dataChange: null,
    display: false,
    isLoaded: false,
    shortName: null,
    updatable: false,
    loadedMultipleSlots: false,
    slots: [],
    bookings: [],
    slotsClosed: [],
    leisureSlots: [],
    startTimePlayground: null,
    timer: null,
    isSticky: false
  }),
  components: {
    Closed,
    Slots: () => import('./slots/Index'),
    Bookings: () => import('./bookings/Index'),
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.display = false;
      }
    },
    'date': function () {
      this.$emit('on:schedule-reload');
    },
    schedule: {
      deep: true,
      handler() {
        this.setStartingTimePlayground();
        this.slots = [];
        this.bookings = [];
        this.slotsClosed = [];
        this.$emit('on:schedule-reload');
      }
    }
  },
  computed: {
    scrollHeader() {
      return this.scrollPosition > 0 && this.isSticky === true ? {marginLeft: '-' + this.scrollPosition + 'px'} : {};
    },
    isNotUpdatable() {
      return this.updatable;
    },
    hasShortName() {
      if (this.shortName === null) {
        return false;
      } else {
        return this.shortName.trim() !== '';
      }
    },
  },
  created() {
    this.loader();
    this.setStartingTimePlayground();
    this.shortName = this.playground.shortName;
  },
  methods: {
    loadMultipleSlotBookings(event = null) {
      const planningDate = this.$moment.utc(this.$store.getters["planning/getDate"]).format(YEAR_DASH_MONTH_DASH_DAY);

      if (!event) {
        this.loadedMultipleSlots = false;
      }

      getPlaygroundMultipleSlots(this.playground.id, planningDate)
        .then((response) => {
          this.leisureSlots = response.data;
        })
        .finally(() => {
          this.loadedMultipleSlots = true;
        })
      ;
    },
    headerToStickyPosition() {
      this.isSticky = true;
    },
    headerToStaticPosition() {
      this.isSticky = false;
    },
    onBookingCanceled(booking) {
      const findBookingToDelete = this.playgroundsBookings.find(el => el['@id'] === booking['@id']);

      if ("undefined" !== typeof findBookingToDelete) {
        this.playgroundsBookings.splice(this.playgroundsBookings.indexOf(findBookingToDelete), 1);
        this.loader();
      }
    },
    onBookingCopy(scope) {
      this.$emit('on:booking-copy', scope);
    },
    onBookingMove(scope) {
      this.$emit('on:booking-move', scope);
    },
    onBookingLoaded(data) {
      this.dataChange = data;
      this.loader();
    },
    showTextField() {
      if (this.params.orientation === 'vertical') {
        this.display = true;
      }
    },
    cancelUpdate() {
      this.display = false;
      this.shortName = this.playground.shortName;
    },
    updateShortName() {
      const data = {
        shortName: this.shortName && this.shortName.trim() !== '' ? this.shortName : null
      };
      putClubPlayground(this.playground.id, data)
        .then((response) => {
          this.display = false;
          this.playground.shortName = response.data.shortName;
          this.shortName = response.data.shortName;
        })
      ;
    },
    loader() {
      this.loadBookingPlayground();
    },
    loadPlaygroundBlocks() {
      this.isLoaded = false;

      const clubData = this.$store.getters["auth/currentClub"];
      let dateString = this.$store.getters["planning/getDate"];

      this.startTimePlayground = this.$moment.utc(dateString + ' ' + this.params.timetable.startAt).tz(clubData.timezone, true).minute(0);

      let timezone = '';
      if (this.$store.getters["auth/currentClub"]) {
        timezone = this.$store.getters["auth/currentClub"].timezone;
      }
      this.slots = [];
      this.slotsClosed = [];
      this.startTimePlayground = this.$moment.utc(dateString + ' ' + this.params.timetable.startAt + '+00:00').tz(clubData.timezone, true).minute(0);
      let lastClosedTime = this.startTimePlayground;
      let firstStart = null;

      this.playground.blocks.sort((a, b) => (a.startAt > b.startAt) ? 1 : ((b.startAt > a.startAt) ? -1 : 0))

      for (const block of this.playground.blocks) {
        const blockTimeStartAt = block.startAt.split('T')[1];
        const blockTimeEndAt = block.endAt.split('T')[1];

        block.startAt = dateString + 'T' + blockTimeStartAt;
        block.endAt = dateString + 'T' + blockTimeEndAt;

        let startAt2 = this.$moment.utc(block.startAt).tz(clubData.timezone, true);
        let endAt2 = this.$moment.utc(block.endAt).tz(clubData.timezone, true);
        startAt2.second(0);
        endAt2.second(0);

        if (endAt2 < startAt2) {
          endAt2.add(1, 'day');
        }
        if (firstStart === null && lastClosedTime < startAt2) {
          const diffBlocks = startAt2.diff(lastClosedTime, 'minutes');
          if (diffBlocks > 0) {
            firstStart = startAt2.clone();
          }
          this.slotsClosed.push({
            date: lastClosedTime.clone(),
            endAt: startAt2.clone(),
            step: diffBlocks,
            bookable: false
          });
          lastClosedTime = endAt2.clone();
        } else if (lastClosedTime === null) {
          lastClosedTime = endAt2.clone();
        } else if (startAt2 > lastClosedTime) {
          const diffBlocks = startAt2.diff(lastClosedTime, 'minutes');
          this.slotsClosed.push({
            date: lastClosedTime.clone(),
            endAt: startAt2.clone(),
            step: diffBlocks,
            bookable: false
          });
          lastClosedTime = endAt2.clone();
        } else {
          lastClosedTime = endAt2.clone();
        }

        // check bookable slot from blocks
        while (startAt2 < endAt2) {
          let defaultPrice = null;
          let bookable = this.checkBookableSlot(startAt2, (block.userClubStepBookingDuration / 60), null);
          let slot = {
            date: startAt2.clone(),
            step: parseInt((block.userClubStepBookingDuration / 60)),
            blockId: block.id,
            block: block,
            blockPrice: defaultPrice,
            bookable: bookable
          };
          let useSmall = false;
          if (!bookable) {
            let durationStepTest = (block.userClubStepBookingDuration / 60);
            const startAt3 = startAt2.clone();
            while (!bookable && durationStepTest > 0) {
              durationStepTest = durationStepTest - 1;
              bookable = this.checkBookableSlot(startAt3, durationStepTest, null);
            }
            if (bookable) {
              slot = {
                date: startAt3.clone(),
                step: parseInt('' + durationStepTest),
                blockId: block.id,
                block: block,
                blockPrice: defaultPrice,
                bookable: bookable
              };
              startAt2.add((block.userClubStepBookingDuration / 60), 'minutes');
              useSmall = true;
            } else {
              const startAt3 = startAt2.clone();
              let durationStepTest = (block.userClubStepBookingDuration / 60);
              //durationStepTest = durationStepTest - 1;
              while (!bookable && durationStepTest > 0) {
                durationStepTest = durationStepTest - 1;
                startAt3.add(1, 'minutes');
                bookable = this.checkBookableSlot(startAt3, durationStepTest, block.nonBookablePeriods);
              }
              if (bookable) {
                slot = {
                  date: startAt3.clone(),
                  step: parseInt('' + durationStepTest),
                  blockId: block.id,
                  block: block,
                  blockPrice: defaultPrice,
                  bookable: bookable
                };
                startAt2.add((block.userClubStepBookingDuration / 60), 'minutes');
                useSmall = true;
              }
            }
          }
          if (!useSmall) {
            startAt2.add((block.userClubStepBookingDuration / 60), 'minutes');
          }
          this.slots.push(slot);
        }
      }
      this.filterClosedSlots();
      this.$nextTick(() => {
        this.isLoaded = true;
      });
      this.isBusy = false;
      this.updatable = false;
    },
    loadBookingPlayground() {
      this.bookings = [];
      this.isBusy = true;

      for (const booking of this.playgroundsBookings) {
        const playgroundId = booking.playgrounds.find(el => el.id === this.playground.id);
        if ("undefined" !== typeof playgroundId) {
          this.bookings.push(booking);
        }
      }
      this.formatedClosedSlot();
      this.loadPlaygroundBlocks();
      if (this.playground.bookingType === MULTIPLE_BOOKING_TYPE) {
        this.loadMultipleSlotBookings();
      } else {
        this.loadedMultipleSlots = true;
      }
    },
    filterClosedSlots() {
      const clubData = this.$store.getters["auth/currentClub"];
      const newClosedSlots = [];
      let lastBlock = this.$moment.utc(this.slots[this.slots.length - 1].date).tz(clubData.timezone).second(0);
      let firstBlock = this.$moment.utc(this.slots[0].date).tz(clubData.timezone).second(0);
      lastBlock.add(this.slots[this.slots.length - 1].step, 'minutes');

      for (const i in this.slotsClosed) {
        const slotStartAt = this.$moment.utc(this.slotsClosed[i].date).tz(clubData.timezone).second(0);
        const slotClosedAt = this.$moment.utc(this.slotsClosed[i].endAt).tz(clubData.timezone).second(0);
        let bookingDuration = null;

        for (const booking of this.bookings) {
          const bookingStartAt = this.$moment.utc(booking.startAt).tz(clubData.timezone).second(0);
          const bookingCloseAt = this.$moment.utc(booking.endAt).tz(clubData.timezone).second(0);
          /* check if booking start at the beginning of the day or not */

          if (bookingStartAt <= slotStartAt) {
            bookingDuration = bookingCloseAt.diff(bookingStartAt, 'minutes');
            this.slotsClosed[i].date = bookingCloseAt;

            if (bookingStartAt.isSame(firstBlock) || bookingStartAt.isAfter(firstBlock)) {
              this.slotsClosed[i].step = 0;
            } else {
              this.slotsClosed[i].step = this.slotsClosed[i].step - bookingDuration;
            }
          } else if ((bookingStartAt > slotStartAt) && (bookingStartAt < slotClosedAt) && (bookingStartAt < lastBlock)) {
            const cloneClosedSlot = slotClosedAt.clone();

            this.slotsClosed[i].endAt = bookingStartAt;
            this.slotsClosed[i].step = bookingStartAt.diff(slotStartAt, 'minutes');
            newClosedSlots.push({
              bookable: false,
              step: cloneClosedSlot.diff(bookingCloseAt, 'minutes'),
              date: bookingCloseAt,
              endAt: cloneClosedSlot,
            });
          } else {
            const storedDate = this.$store.getters["planning/getDate"];
            const slotDate = this.$moment.utc(storedDate).tz(clubData.timezone);
            const hhFormat = this.params.timetable.endAt.split(':')[0];
            const mmFormat = this.params.timetable.endAt.split(':')[1];

            slotDate.set({hour: hhFormat, minute: mmFormat, second: 0, millisecond: 0});
            slotDate.toISOString();
            slotDate.format();
            if (bookingStartAt.isSame(lastBlock)) {
              newClosedSlots.push({
                bookable: false,
                step: slotDate.diff(bookingCloseAt, 'minutes'),
                date: bookingCloseAt,
                endAt: slotDate,
              });
            } else if (bookingStartAt.isAfter(lastBlock)) {
              newClosedSlots.push({
                bookable: false,
                step: bookingStartAt.diff(lastBlock, 'minutes'),
                date: lastBlock,
                endAt: bookingStartAt,
              });
            }
          }
        }

      }
      this.slotsClosed.push(...newClosedSlots);
    },
    formatedClosedSlot() {
      const arrayClosedSlot = [];
      let updGlobal = false;
      this.slotsClosed.forEach(slot => {
        let upd = false;
        const start = slot.startAt;
        const end = slot.endAt;

        this.bookings.forEach(booking => {

          if (upd) {
            // Nothing todo
          } else if (booking.startAt < start && booking.endAt > end) {
            upd = true;
          } else if (booking.startAt > start && booking.endAt < end) {
            const diff1 = start.clone().diff(booking.startAt.clone(), 'minutes');
            arrayClosedSlot.push({
              date: start.clone(),
              endAt: booking.startAt.clone(),
              step: diff1,
              bookable: false
            });
            const diff2 = booking.endAt.clone().diff(end.clone(), 'minutes');
            arrayClosedSlot.push({
              date: booking.endAt.clone(),
              endAt: end.clone(),
              step: diff2,
              bookable: false
            });
            upd = true;
          } else if ((booking.startAt >= start && booking.startAt < end)) {
            const diff = start.clone().diff(booking.startAt.clone(), 'minutes');
            arrayClosedSlot.push({
              date: start.clone(),
              endAt: booking.startAt.clone(),
              step: diff,
              bookable: false
            });
            upd = true;
          } else if (booking.endAt >= start && booking.endAt < end) {
            const diff = booking.endAt.clone().diff(end.clone(), 'minutes');
            arrayClosedSlot.push({
              date: booking.endAt.clone(),
              endAt: end.clone(),
              step: diff,
              bookable: false
            });
            upd = true;
          } else {
            arrayClosedSlot.push(slot);
          }
        });
        if (upd) {
          updGlobal = true;
        }
      });
      if (updGlobal) {
        this.slotsClosed = arrayClosedSlot;
        this.formatedClosedSlot();
      }
    },
    setStartingTimePlayground() {
      if (this.schedule.today) {
        this.startTimePlayground = this.$moment.utc();
      } else {
        let tomorrow = this.$moment.utc().tz(this.$store.getters["auth/currentClub"]).add(1, 'days');

        this.startTimePlayground = this.$moment.utc(tomorrow).tz(this.$store.getters["auth/currentClub"]);
      }
    },
    checkBookableSlot(start, duration, nonBookablePeriods) {
      const clubData = this.$store.getters["auth/currentClub"];
      let bookable = true;
      const startAt = start.clone();
      const endAt = startAt.clone();
      endAt.add(duration, 'minutes');

      this.bookings.forEach(booking => {
        if (this.playground.bookingType !== MULTIPLE_BOOKING_TYPE) {
          const startAtFormat = this.$moment.utc(booking.startAt).tz(clubData.timezone).second(0);
          const endAtFormat = this.$moment.utc(booking.endAt).tz(clubData.timezone).second(0);

          if (
            (
              startAt >= this.$moment.utc(startAtFormat).tz(clubData.timezone).second(0)
              &&
              startAt < this.$moment.utc(endAtFormat).tz(clubData.timezone).second(0)
            )
            || (endAt > this.$moment.utc(startAtFormat).tz(clubData.timezone).second(0)
            && endAt <= this.$moment.utc(endAtFormat).tz(clubData.timezone).second(0))
            || (startAt <= this.$moment.utc(startAtFormat).tz(clubData.timezone).second(0)
            && endAt > this.$moment.utc(startAtFormat).tz(clubData.timezone).second(0))
          ) {
            bookable = false;
          }
        }
      });
      return bookable;
    },

    groupBookingsBySlot(start, duration) {
      const clubData = this.$store.getters["auth/currentClub"];
      const startAt = start.clone();
      const endAt = startAt.clone();
      endAt.add(duration, 'minutes');

      let bookingList = [];
      this.bookings.forEach(booking => {
        if (booking.activityType === LEISURE_TYPE || booking.activityType === LESSON_TYPE && booking.playgrounds[0]['@id'] === this.playground['@id']) {

          const startAtBooking = this.$moment.utc(booking.startAt).tz(clubData.timezone).second(0);
          const endAtBooking = this.$moment.utc(booking.endAt).tz(clubData.timezone).second(0);

          if (
            (
              startAtBooking >= startAt
              &&
              startAtBooking < endAt
            )
            ||
            (
              startAtBooking <= startAt
              &&
              endAtBooking > startAt
            )
            ||
            (
              endAtBooking > startAt
              &&
              endAtBooking <= endAt
            )
            ||
            (
              endAtBooking >= endAt
              &&
              startAtBooking < endAt
            )
          ) {
            bookingList.push(booking)
          }
        }
      });

      return bookingList;
    },
    isSamePlayground(data) {
      return this.playground['@id'] === data.slotProps.playground['@id'];
    }
  },
  mounted() {
    this.$bus.$on('on:multiple-bookings:update', (data) => {
      if (this.isSamePlayground(data)) {
        this.loadMultipleSlotBookings('on:multiple-bookings:update');
      }
    });

    this.$bus.$on('on:booking-update', (booking) => {
      for (let i in this.bookings) {
        if (this.bookings[i].id === booking.id) {
          hydrate(this.bookings[i], booking);
          this.$store.commit('planning/addOperation', booking);
          break;
        }
      }
    });

    this.$bus.$on('on:booking-moved',
      (data) => {
        if (this.playground.id === data.from) {
          this.loader();
        }
      })
    ;

    this.$bus.$on('on:booking-canceled',
      (booking) => {
        this.onBookingCanceled(booking);
      })
    ;
  },
  beforeDestroy() {
    try {
      this.$bus.$off('on:booking-moved');
      this.$bus.$off('on:booking-update');
      this.$bus.$off('on:booking-canceled');
      this.$bus.$off('on:multiple-bookings:update');
    } catch (err) {
    }
  }
}
</script>
<style lang="scss" scoped>

.container-playground {
  min-width: 100px;
  width: fit-content;
  overflow: unset;
}

.flex-column {
  min-width: 180px;
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
}

.flex-row {
  min-height: 80px;
  max-height: 100px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.container-playground.flex-column .hover-step {
  height: var(--height);
}

.container-playground.flex-row .hover-step {
  width: var(--height);
}

.name-block {
  text-align: center;
  background-color: rgba(227, 233, 239, 0.5);
  color: #4D4F5C;
  font-size: 1.09rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .name-block {
    background-color: rgba(227, 233, 239, 1);
  }
}

.name-block-r {
  min-width: 80px;
  max-width: 80px;
  min-height: 100%;
  max-height: 100%;
}

.name-block-c {
  max-width: 100%;
  min-width: 100%;
  min-height: 70px;
  max-height: 70px;
  display: flex;
  justify-content: space-between;
}

.name-block-r label {
  text-overflow: ellipsis;
  align-items: center;
  align-content: center;
  overflow: hidden;
  font-family: Avenir;
  font-size: 0.9rem;
  padding: 8px;
}

.name-block-c label {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-family: Avenir;
  font-size: small;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

/deep/ .form-control-sm {
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
}

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.custom-right-borders {
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.left-borders {
  border-bottom-left-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
}

.custom-input.form-control-sm {
  height: calc(1.5em + 0.61rem);
  font-size: 0.875rem;
  line-height: 0;
}
</style>


<style scoped lang="scss">
@import "@lazy/planning/_schedule-playground.scss";
</style>
